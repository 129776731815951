// Modules
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import React from 'react'

// PropTypes
const propTypes = {
  metaData: PropTypes.shape({
    description: PropTypes.string,
    ogImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    robots: PropTypes.shape({
      follow: PropTypes.bool,
      index: PropTypes.bool,
    }),
    title: PropTypes.string,
  }),
}

const MetaData = ({ metaData }) => {
  const follow = metaData?.robots?.follow ? 'follow' : 'nofollow'
  const index = metaData?.robots?.index ? 'index' : 'noindex'

  return (
    <>
      {metaData?.title && (
        <Helmet>
          <title>{metaData.title}</title>
          <meta
            content={metaData.title}
            property='og:title'
          />
        </Helmet>
      )}

      {metaData?.description && (
        <Helmet>
          <meta
            content={metaData.description.toString()}
            name='description'
          />
          <meta
            content={metaData.description.toString()}
            property='og:description'
          />
        </Helmet>
      )}

      <Helmet>
        <meta
          content={`${index} ${follow}`}
          name='robots'
        />
      </Helmet>

      {metaData?.ogImage?.childImageSharp?.fluid?.originalImg && (
        <Helmet>
          <meta
            content={metaData.ogImage.childImageSharp.fluid.originalImg}
            property='og:image'
          />
        </Helmet>
      )}
    </>
  )
}

MetaData.propTypes = propTypes
export default MetaData
